$cardBackground: white;
$text: rgba(0, 0, 0, 0.54);

.card-wrap {
  max-width: 33rem;
  height: auto;
  box-shadow: 5px 5px 8px grey;
  margin: 2%;
  transition: all 0.5s ease-in-out;
  @media (max-width: 400px) {
    min-width: 16rem;
    // height: 38rem;
  }
  &:hover {
    transform: scale(1.08);
  }

  a {
    text-decoration: none;
    height: 100%;
    display: inline-block;
    // &:hover{
    //   background-color: aliceblue;
    // }
  }
}

.card-img {
  height: 50vh;
  object-fit: cover;
  width: 100%;
}

.card-content {
  width: 90%;
 margin-left: 20px;
 margin-right: 20px

}

.card-title {
  font-size: 2.75rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.2;
  letter-spacing: -0.00833em;
  margin-bottom: 0.35em;
  color: black;
}

.card-subtitle {
  font-size: 1.5rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
  margin-bottom: 0.35em;
  color: black;
  text-decoration: none;
}

.card-text {
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  color: $text;
}

.file-wrap {
  width: 330px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
  height: 190px;
  box-shadow: 5px 5px 8px grey;
  margin: 2%;
  @media (max-width: 440px) {
    width: 240px;
    height: auto;
  }
  a {
    text-decoration: none;
    height: 100%;
    display: inline-block;
  }

}

.tech-link {
  font-weight: 300;
  font-size: 15px;
  line-height: 2;
  color: black;
  padding-top: 2%;
  padding-bottom: 2%;
  &:hover {
    color:rgba(26, 26, 26, 0.54);
  }
}

.card-detail-group-wrap {
  height:100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2%;
  // @media (max-width: 440px) {
  //   flex-direction: column;
  // }
}


.card-detail-image {
  width: 33%;
  height: auto;
}