$primaryBackground: #2d2d2d;
$primaryOffset: #4d4d4d;
$secondaryBackground: #D0D6D7;
$navBackground: white;
$bodyBackground: white;
$headingText: #D0D6D7;
$accentBlue: #A9F4FF;

$breakpoint: 1320px;

@mixin transitions($sec) {
  transition: all $sec ease-in-out;
}

@mixin text-font() {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}

@mixin text-title($color) {
  font-size: 1.8rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 100;
  line-height: 1.334;
  letter-spacing: 0em;
  color: $color;
  text-align: center;
}

@mixin position-sticky() {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -o-sticky;
  position: -ms-sticky;
  position: sticky;
  display: block;
  top: 144px;
}


* {
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

body {
  background-color: $bodyBackground;
  margin-top: 147px;

  @media (max-width: $breakpoint) {
    margin-top: 0px;
  }

  margin-bottom: 0px;
}

h2 {
  color: $headingText;
}

// ************************************ Mobile Navigation Styles ************************************* 
.mobile-nav-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 2%;


  h2 {
    color: $accentBlue;
    background-color: $primaryBackground;
    font-weight: 200;
    width: auto;
    padding-left: 6%;
    padding-right: 6%;
    
  }
}

.mobile-tab-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: sticky;
  top: 0;
  overflow: hidden;
}

.mobile-logo {
  height: 180px;
  object-fit: contain;
}

#mobile-glo-logo {
  width: auto;
  height: 8vw;
  max-height: 36px;
  margin-top: 6%;
}

#mobile-airlux-logo {
  margin-top: 6%;
  height: 10vw;
  max-height: 50px;
  width: auto;
}

#mobile-logo-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

// ************************************ Navigation Styles ************************************* 

#nav-glo-logo {
  width: auto;
  height: 36px;
  padding-top: 1%;
  
}

.head-container {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: $navBackground;
  position: fixed;
  top: 0px;
  box-shadow: 0px 2px 6px grey;
  @include transitions(1s);
  z-index: 999;
}

.logo-img {
  width: 260px;
  margin-top: 10px;
  @include transitions(1.2s);
}

.logo-img-startup {
  width: 700px;
  margin-left: 31%;
}

.headTextWrap {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include text-font();
  margin-right: 2%;
}

.headText {
  color: black;
  @include text-font();
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  padding-right: 4%;
  @include transitions(1s);

  @media (max-width: 420px) {
    font-size: 14px;
  }
}

.fade-in {
  opacity: 1;
  transition: opacity 1s ease;
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease;
}

.tab-container {
  display: flex;
  flex-direction: row;
  // flex-wrap: wrap;
  margin-left: 14%;
  margin-top: 110px;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: $breakpoint) {
    width: 100%;
    margin-left: 0;
    width: 100%;
    justify-content: center;
    margin-top: 30px
  }
  @media (max-width: 382px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.tab {
  width: 12%;
  min-width: 88px;
  text-align: center;
  padding: 6px;
  font-size: 15px;
  user-select: none;

  h4 {
    font-weight: 300;
  }

  @media (max-width: 370px) {
    padding: 8px;
    width: 16%;
  }

}

.active {
  background-color: $primaryBackground;
  color: white;
  border-bottom: 3px solid $accentBlue;
  box-shadow: 2px 2px 6px grey;
  font-size: 17px;

  a {
    font-weight: 400;
  }
}

.small-logo-img {
  height: 50px;
  width: auto;
}

#small-logo-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 18px;

  a {
    width: auto;
    justify-content: center;
    align-items: center;
  }
}

// ************************  Home Styles ****************************

.hero-img {
  background-attachment: fixed;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  min-height: 100vh;
  @include position-sticky();
  @media (max-width: $breakpoint) {
    background-attachment: scroll;
  }
}


.divider-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100px;
  margin-top: 100px;
  background-color: $primaryOffset;
  h1 {
    font-size: 2.75rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: -0.00833em;
    color: $accentBlue;
  }
  

  @media (max-width: $breakpoint) {
    margin-top: 0%;
  }
}


.divider-text {
  @include text-title($color: $headingText);
}

.divider-subhead {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $bodyBackground;
  min-height: 60px;
  margin: 0;
}

.divider-subtext {
  width: 60%;
  padding-top: 22px;
  padding-bottom: 18px;
  text-align: left;

  p {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  }

  @media (max-width: 820px) {
    width: 80%
  }

  @media (max-width: 650px) {
    width: 90%
  }
}

.divider-line {
  background-color: $primaryBackground;
  height: 1.5px;
  width: 30%;
  margin-top: 10px;

  @media (max-width: 600px) {
    width: 60%;
  }
}

.card-container {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 1%;
  padding-top: 1%;
}

.layout-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.process-container {
  @include position-sticky();
  z-index: 11;
  height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: $primaryBackground;

  @media (max-width: $breakpoint) {
    height: 100vh;
    top: 0px;
  }

  @media (max-width: 810px) {
    flex-direction: column;
  }

  @media (max-height: 800px) {
    flex-direction: row;
  }
}

#process-three {
  z-index: 14;
}

.process-container-light {
  @include position-sticky();
  top: 144px;
  z-index: 12;
  height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: $primaryOffset;

  @media (max-width: $breakpoint) {
    height: 100vh;
    top: 0px;
  }

  @media (max-width: 810px) {
    flex-direction: column;
  }

  @media (max-height: 800px) {
    flex-direction: row;
  }
}

.process-text {
  width: 50%;
  font-weight: 100;
  font-size: 18px;
  line-height: 1.5;
  color: #D0D6D7;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 810px) {
    width: 90%;
    padding-top: 6%;
  }

  @media (max-width: $breakpoint) {
    flex-direction: column;
  }

  h2 {
    @include text-title($color: $headingText);
  }

  ul {
    margin-top: 2%;
  }

  li {
    list-style: square;
    margin-top: 6%;
    margin-bottom: 6%;
    font-size: 18px;
    margin-bottom: 0.35em;
    line-height: 1.6;

    @media (max-height: 420px) {
      font-size: 16px;
      margin-top: 2%;
      margin-bottom: 2%;
    }
  }
}

#statement-block {
  width: 100%;
  background-color: $bodyBackground;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#statement-wrap {
  background-color: rgb(244, 244, 244);
  width: auto;
  margin-bottom: 3%;
  margin-top: 3%;
  padding-left: 3%;
  padding-right: 3%;
  border-radius: 6px;
  box-shadow: 5px 5px 10px grey;

  @media (max-width: 725px) {
    width: 80%;
    margin-top: 6%
  }

  h4 {
    font-weight: 300;
    font-size: 18px;
    line-height: 2;
    color: black;
    padding-top: 2%;
    padding-bottom: 2%;
  }
}

.block-divider {
  position: relative;
  min-height: 100px;
  width: auto;
  background-color: $primaryBackground;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 4%;
  padding-bottom: 4%;
  padding-left: 30%;
  padding-right: 30%;
  font-weight: 200;
  font-size: 18px;
  line-height: 1.5;
  color: #D0D6D7;

  @media (max-width: $breakpoint) {
    padding-left: 5%;
    padding-right: 5%;
  }
}

.hero-image {
  @include position-sticky();

  background-attachment: 'fixed';
  background-position: 'center';
  background-size: 'cover';
  height: '100vh';
}

#home-footer {
  @include position-sticky();
  top: 144px;
  min-height: 90vh;
  z-index: 15;
  background-color: $bodyBackground;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  @media (max-width: $breakpoint) {
    height: 100vh;
    top: 0px;
  }

  @media (max-height: 690px) {
    position: relative;
    height: auto;
    padding-bottom: 20px;
  }
}

#home-footer-two {
  @include position-sticky();
  min-height: 55vh;
  z-index: 16;
  background-color: $primaryBackground;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: $breakpoint) {
    height: 100vh;
    top: 0px;
  }

  @media (max-height: 690px) {
    position: relative;
    height: auto;
  }
}

// ************************  Product Styles ****************************
.project-content-wrap {
  min-height: 800px;
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media (max-width: $breakpoint) {
    max-width: 100%;
    min-height: 0px;
  }
}

.youtube {
  width: 60%;
  max-width: 1035px;
  margin-top: 1%;
  margin-bottom: 1%;

  @media (max-width: $breakpoint) {
    width: 90%;
  }

  @media (max-width: 810px) {
    width: 100%;
    margin-top: 6%;
  }
}

// ************************  Techincal Styles ****************************
.technical-wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: start;
  height: auto;
  @media (max-width: $breakpoint) {
    flex-direction: column;
  }
}


#technical-text {
 padding-top: 4%;
 padding-left: 8%;
 padding-right: 18%;
 font-size: 1.4rem;
 font-family: "Roboto", "Helvetica", "Arial", sans-serif;
 font-weight: 100;
 line-height: 1.334;
 letter-spacing: 0em;
 color: black;
 @media (max-width: $breakpoint) {
  width: 80%;

}

 p{
  padding-top: 6%;
  padding-bottom: 6%;
 }
}


#technical-hero-img {
  height: 45rem;
  width: auto;
  @media (max-width: $breakpoint) {
    width: 100%;
    height: auto;
  }
}

// ************************  Footer Styles ****************************
.footer-container {
  @include position-sticky();
  height: 90vh;
  top: 144px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 12;
  background-color: $bodyBackground;
}


#quote-wrap {
  background-color: #2d2d2d;
  color: #A9F4FF;
  text-align: center;
  border-radius: 4px;
  width: 20%;
  height: 444 px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 2%;
  min-width: 300px;
  margin-bottom: 4%;

  @media (max-width: 1124px) {
    margin-top: 2%;
  }
}

.quote-text {
  margin: 4%;
}


.quote-button {
  border: none;
  border-radius: 4px;
  padding-top: 7px;
  padding-bottom: 8px;
  font-size: 14px;
  font-weight: 300;
  width: 60%;
  background-color: #A9F4FF !important;
  margin-bottom: 5% !important;
  margin-top: 10% !important;
  text-decoration: none !important;
  transition: transform .4s !important;

  &:hover {
    transition: transform .4s;
    transform: scale(1.07);
    cursor: pointer;
  }
}

#lower-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  z-index: 20;
  background-color: $primaryBackground;
  padding-top: 3%;

  @media (max-width: 1128px) {
    flex-direction: column;
    justify-content: center;
  }
}

#contact-wrap {
  width: 30%;
  color: $bodyBackground;
  padding-left: 20%;
  font-weight: 200;
  font-size: 16px;

  @media (max-width: 1128px) {
    width: 90%;
  }

  @media (max-width: 540px) {
    width: 100%;
    padding-left: 4%
  }
}

.map-container {
  width: 400px;
  height: 400px;
  overflow: hidden;

  @media (max-width: 1128px) {
    margin-left: 20%;
    margin-top: 12%;
  }

  @media (max-width: 540px) {
    margin-left: 0%;
    width: 100vw;
  }
}

.contact-label {
  text-decoration: underline;
  margin: 0;
  margin-bottom: 9px;
  margin-top: 26px;
  @include text-font();
  font-size: 18px;
}

.contact-text {
  font-size: 14px;
}

.email-wrap {
  padding-top: 40px;
}

.email-link {
  color: $headingText;
  font-size: 16px;
  text-transform: uppercase;
}

#copywright {
  width: 90%;
  background-color: $primaryBackground;
  color: rgb(135, 135, 135);
  font-size: 11px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 30px;
  text-align: right;

  a {
    background: -webkit-linear-gradient(#eee, #0C8EFC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}


// ************************************ Form Styles ************************************* 
#form-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

#form-inner {
  width: 25%;

  @media (max-width: 1500px) {
    width: 50%;
  }

  @media (max-width: 700px) {
    width: 80%;
  }
}

.small-text-input {
  height: 50px;
  @include text-font();
}

.large-text-input {
  height: 20vh;
  padding-top: 14px;
  @include text-font();
  resize: none;
}

.input-wrap {
  margin: 4%;

  input:valid[type=text],
  input:valid[type=email] {
    background-color: rgb(175, 243, 175, 0.16);
    opacity: 50;
  }

  input:focus[type=text],
  input:focus[type=email] {
    border: 2px solid rgb(241, 48, 48, .80);
    outline: none;
  }

  input:focus:valid[type=text],
  input:focus:valid[type=email] {
    border: 2px solid rgb(175, 243, 175, 0.80);
    outline: none;
  }

  textarea:valid {
    background-color: rgb(175, 243, 175, 0.16);
    opacity: 50;
  }

  textarea:focus {
    border: 2px solid rgb(241, 48, 48, .80);
    outline: none;
  }

  textarea:focus:valid {
    border: 2px solid rgb(175, 243, 175, 0.80);
    outline: none;
  }
}



#button-wrap {
  margin-bottom: 6%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#submit-button {
  color: black;
  width: 190px;
  height: 60px;
  border: none;
  background-color: $accentBlue;
  text-transform: uppercase;
  font-weight: 500;
  box-shadow: 4px 4px 4px 0px $primaryBackground;

  &:hover {
    transition: transform .4s;
    transform: scale(1.07);
    cursor: pointer;
  }
}

#file-wrap {
  @include text-font();
  color: grey;
  border: 1px solid grey;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 4px;

  span {
    margin-bottom: 16px;
  }

  input {
    padding-top: 16px;
  }
}